import { createSlice } from '@reduxjs/toolkit';
import { MIND, KNOWLEDGE } from './menuConst';

// initial state
const initialState = {
	previousMenu: ['main'],
	drawerOpen: false,
	mode: KNOWLEDGE
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		activeItem(state, action) {
			state.previousMenu = action.payload;
		},

		openDrawer(state, action) {
			state.drawerOpen = action.payload;
		},
		setMode(state, action) {
			state.mode = action.payload;
		}
	}
});

export default menu.reducer;

export const { activeItem, openDrawer, setMode } = menu.actions;
